import React, { useState } from "react";
import {
	FaFacebookF,
	FaTwitter,
	FaInstagram,
	FaLinkedinIn,
} from "react-icons/fa";
import "./Newsletter.scss";
const Newsletter = () => {
	const [email, setEmail] = useState();
	const handleChange = (event) => {
		setEmail(event.target.value);
	};
	console.log(email);
	return (
		<div className="newsletter-section">
			<div className="newsletter-content">
				<span className="small-text">Newsletter</span>
				<span className="big-text">
					Sign up for latest updates and offers
				</span>
				<div className="form">
					<input
						type="text"
						name="email"
						placeholder="Email Address"
						value={email}
						onChange={handleChange}
					/>
					<button>Subscribe</button>
				</div>
				<span className="text">
					Will be used in accordance with our Privacy Policy
				</span>
				<span className="social-icons">
					<div className="icon">
						<FaLinkedinIn size={14} />
					</div>
					<div className="icon">
						<FaFacebookF size={14} />
					</div>
					<div className="icon">
						<FaTwitter size={14} />
					</div>
					<div className="icon">
						<FaInstagram size={14} />
					</div>
				</span>
			</div>
		</div>
	);
};

export default Newsletter;
